import React from "react";
import { graphql } from "gatsby";
import { Link } from "@intractive/gatsby-plugin-react-intl";
import * as PropTypes from "prop-types";
import Helmet from "react-helmet";
import Layout from "../Layout";

const style = require("./index.module.css");

const propTypes = {
  data: PropTypes.object.isRequired,
}

class PageTemplate extends React.Component {
  render() {
    const page = this.props.data.contentfulPage

    const {
      slug,
      metaDescription,
      pageTitle,
      text,
      subpages,
    } = page

    return (
      <Layout>
        <Helmet>
          <title>{pageTitle} · STIP</title>
          <meta name="description" content={metaDescription} />
        </Helmet>
        <div className="row main-content">
          <div className="large-3 medium-3 columns sidebar hide-for-small">
            {subpages &&
              <div className="nav-sub">
                <ul>
                    <li><Link to={`/${slug}/`} activeClassName={style.active}>{page.pageTitle}</Link>
                    <ul>
                      {page.subpages && page.subpages.map(function(subpage, index){
                        return (
                          <li key={index}><Link to={`/${slug}/${subpage.slug}/`} activeClassName={style.active}>{subpage.pageTitle}</Link></li>
                        )
                      })}
                    </ul>
                  </li>
                </ul>
              </div>
            }
          </div>
          <div className="large-9 medium-9 columns">
            <section>
              <h1>{pageTitle}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: text.childMarkdownRemark.html,
                }}
              />
            </section>
          </div>
        </div>
      </Layout>
    )
  }
}

PageTemplate.propTypes = propTypes

export default PageTemplate

export const pageQuery = graphql`
  query($slug: String!, $node_locale: String) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $node_locale }) {
      slug
      metaDescription
      pageTitle
      text {
        childMarkdownRemark {
          html
        }
      }
      subpages {
        slug
        pageTitle
      }
    }
  }
`
